/*
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 15:38:46
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-21 22:48:06
 * @FilePath: \商务厅PC\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from 'vue'
import Router from 'vue-router'
import mainIndex from '../view/mainIndex.vue'

import newsIndex from '../view/newsIndex.vue'

import loginPage from '../view/login/index.vue'
import sign from '../view/login/sign.vue'
import exhibitionGuide from '../view/exhibitionGuide.vue'

import liaoQiaExpress from '../view/liaoQiaExpress/index.vue'
import liaoningSituation from '../view/liaoningSituation/index.vue'

import emphasisDevelopmentZone from '../view/openLiaoning/emphasisDevelopmentZone.vue'
import pilotFreeTradeZone from '../view/openLiaoning/pilotFreeTradeZone.vue'
import ebay from '../view/openLiaoning/ebay.vue'
import previousAchievements from '../view/previousAchievements/index.vue'
import supplyPurchase from '../view/supplyPurchase/index.vue'
import consumptionLiaoning from '../view/consumptionLiaoning/index.vue'
import ImportantActivity from '../view/ImportantActivity/index.vue'
import NavigationPage from '../view/NavigationPage/index.vue'
import HighlightMoment from '../view/HighlightMoment/index.vue'
import detailpageOne from '../view/detailPage/detailpageOne.vue'
import AttractInvestment from '../view/AttractInvestment/index.vue'


import detailpageTwo from '../view/detailPage/detailpageTwo.vue'
import ProjectLibrary from '../view/ProjectLibrary/index.vue'
import KeyPoint from '../view/KeyPoint/index.vue'
import previousAchievementsDET from '../view/previousAchievements/detail.vue'
import liveVideo from '../view/WonderfulCom/liveVideo.vue'

import ExhibitionService from '../view/ExhibitionService/index.vue'


Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'mainIndex',
      component: mainIndex
    },
    {
      path: '/ExhibitionService',
      name: '展会服务',
      component: ExhibitionService
    },
    {
      path: '/liveVideo',
      name: '视频回顾',
      component: liveVideo
    },

    {
      path: '/previousAchievementsDET',
      name: '详情',
      component: previousAchievementsDET
    },
    {
      path: '/KeyPoint',
      name: '详情',
      component: KeyPoint
    },
    {
      path: '/ProjectLibrary',
      name: '招商项目库',
      component: ProjectLibrary
    },

    {
      path: '/AttractInvestment',
      name: '招商要闻',
      component: AttractInvestment
    },

    {
      path: '/detailpageOne',
      name: '详情',
      component: detailpageOne
    },
    {
      path: '/detailpageTwo',
      name: '详情',
      component: detailpageTwo
    },
    {
      path: '/HighlightMoment',
      name: '高光时刻',
      component: HighlightMoment
    },
    {
      path: '/ImportantActivity',
      name: '重要活动',
      component: ImportantActivity
    }, {
      path: '/NavigationPage',
      name: '辽洽导航',
      component: NavigationPage
    },
    {
      path: '/liaoQiaExpress',
      name: '辽洽快讯',
      component: liaoQiaExpress
    },
    {
      path: '/liaoningSituation',
      name: '新质辽宁',
      component: liaoningSituation
    },
  
    {
      path: '/openLiaoning',
      name: '重点开发区',
      component: emphasisDevelopmentZone
    },
    {
      path: '/pilotFreeTradeZone',
      name: '自贸试验区',
      component: pilotFreeTradeZone
    },
    {
      path: '/ebay',
      name: '跨境电商',
      component: ebay
    },
    {
      path: '/previousAchievements',
      name: '历届盛况',
      component: previousAchievements
    },
    {
      path: '/supplyPurchase',
      name: '供采对接',
      component: supplyPurchase
    },
    {
      path: '/consumptionLiaoning',
      name: '消费辽宁',
      component: consumptionLiaoning
    },

    {
      path: '/newsIndex',
      name: '新闻资讯',
      component: newsIndex
    },

    {
      path: '/loginPage',
      name: 'loginPage',
      component: loginPage
    },
    {
      path: '/sign',
      name: 'sign',
      component: sign
    },


    {
      path: '/exhibitionGuide',
      name: 'exhibitionGuide',
      component: exhibitionGuide
    },

  ]
});
