<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 09:05:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-20 15:20:59
 * @FilePath: \pc\src\view\detailPage\detailpageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        <div class="title">

            <img src="../../assets/标题装饰.png" />
            <span>{{ query }}</span>
        
        </div>
        <div>
            <el-input v-model="input" style="width: 220px;" placeholder="请输入搜索内容"></el-input>
            <!-- <el-button style="margin-left: 20px;" @click="getList()">搜索</el-button> -->
            <el-button type="primary"  style="margin-left: 20px;" size="small" @click="getList()">搜索</el-button>
        </div>
        <div class="content" v-if="attractImportantNews.length">
         

            <div style="cursor: pointer;" @click="goTo('detailpageTwo', item)"
                v-for="(item, index) in attractImportantNews" :key="index" class="att">
                <div>
                    <img :src="item.journalismPhoto" alt="">
                </div>
                <div>
                    <div class="titless">{{ item.journalismTitle }}</div>
                    <div class="contss">{{ item.spare1 }}</div>
                    <div class="datess">{{ item.journalismDate }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['query'],
    data() {
        return {
            input: '',
            titleJumpList: [
                {
                    url: "consumptionLiaoning",
                    name: "消费辽宁"

                }, {
                    url: "AttractInvestment",
                    name: this.query

                },
            ],

            attractImportantNews: [], //招商要闻
        }
    },
    mounted() {

        this.getList()
    },
    methods: { //跳转 
        getList() {
            this.$baseRequest('/maintain/journalism/findList', {
                journalismCategory: "消费辽宁",
                journalismSecondaryClassification: this.query,
                journalismTitle: this.input
            }, 'post').then(res => {
                this.attractImportantNews = res.data;

            })
        },
        goTo(url, item) {

            let params = {
                name: this.query,
                id: item.id,
                titleJumpList: JSON.stringify(this.titleJumpList)
            }
            this.$emit('jumpUrl', url, params)
        },
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
    }

}
</script>
<style>
.el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #EA0E1E;
}
.el-button:active {
    color: #EA0E1E;
    border-color: #EA0E1E;
}
.el-button:focus, .el-button:hover {
    color: #EA1423;
    border-color: #F2ACB2;
    background-color: #FFDBDF;
}
</style>
<style lang="scss" scoped>
.contss {
    font-size: 20px;
}
.titless {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.att {
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid #F8F9FB;
    padding-bottom: 20px;

    img {
        width: 280px;
        height: 180px;
        margin-right: 20px;
        border-radius: 5px;
    }
}

.body {
    padding: 20px 280px;
    

    .content {
        margin-top: 30px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
    }

    .title {
        margin-top: 20px;
        font-family: 'SourceHanSansSC-Regular';
        font-size: 26px;
        color: #000;
        margin-bottom: 20px;

        img {
            width: 20px;
            height: auto;
        }

        span {
            margin: 0px 10px;
        }
    }
}

.tupian {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.conts {
    margin-top: 30px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 140%;

}

.title {
    height: 40px;

    img {
        width: 25px;
        height: 25px;
    }

    >span {
        line-height: 40px;
        margin-left: 9px;
        font-size: 29px;
        font-family: 'DingTalk_JinBuTi_Regular';
    }

    .view-more {
        float: right;
        line-height: 40px;
        color: #a7abc0;
        font-size: 16px;
        cursor: pointer;

        img {

            width: 13px;
            height: auto;
        }
    }
}
</style>