<template>
    <div class="cloudBody">

        <div class="titleCon">
            <VueSlickCarousel v-bind="settings" class="titleLeft" @afterChange="swipe">
                <div v-for="(item, index) in list" class="titleItem"
                    :class="chooseName == index ? 'chooseYes' : 'chooseNo'">
                    {{ item }}
                </div>
            </VueSlickCarousel>
            <div class="titleRight">
                <el-input style="width: calc(100% - 70px)" class="inputSearch" v-model="input"
                    placeholder="请输入搜索内容"></el-input>
                <el-button @click="getList()" style="margin-left: 10px;" type="primary" size="small">搜索</el-button>
            </div>
        </div>

        <div class="exhibitionList" v-if="picList.length">

            <el-carousel indicator-position="outside" class="ExhibitionLeftHeight">
                <el-carousel-item v-for="(item, index) in picList[chooseName].url" :key="index + 'c'">
                    <div style="cursor: pointer;">
                        <el-image class="carousel" :src="item"></el-image>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div>
            <div class="title">

                <img src="../../assets/标题装饰.png" />
                <span>{{ list[chooseName] }}企业</span>

            </div>
        </div>
        <div class="companyFlex">
            <div v-for="(item, index) in companyList" :key="index + 'company'" class="companyItem"
                @click="goTo('CloudDetail', item.id)">
                <div class="headerLeft">
                    <div>
                        <img class="imgHeader" :src="item.photo" alt="">
                    </div>
                    <div>
                        {{ item.title }}
                    </div>
                </div>
                <div>
                    <img class="arrowImg" src="@/assets/列表箭头.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VueSlickCarousel from 'vue-slick-carousel'
export default {
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            picList: [],
            companyList: [],
            urlImg: require('../../assets/辽洽导航背景图.png'),
            input: '',
            list: ['沈阳展区', '大连展区', '鞍山展区', '抚顺展区', '本溪展区', '丹东展区', '锦州展区', '营口展区', '阜新展区', '辽阳展区', '盘锦展区', '铁岭展区', '朝阳展区',
                '葫芦岛展区', '沈抚示范展区'],
            list2: ['沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市', '锦州市', '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市',
                '葫芦岛市', '沈抚示范区'
            ],
            chooseName: 0,
            settings: {
                "dots": false,
                "infinite": true,
                "initialSlide": 0,
                "speed": 500,
                "slidesToShow": 5,
                "slidesToScroll": 1,
                "swipeToSlide": true,
                "autoplay": false,
                "focusOnSelect": true,
            }
        }
    },
    mounted() {

        this.picList = window.PLATFROM_CONFIG.picList;

        this.getList()
    },
    methods: {

        getList() {
            this.$baseRequest('/not/online/exhibition/findList', {
                province: this.list2[this.chooseName],
                title: this.input
            }, 'post').then(res => {
                this.companyList = res.data;
            })
        },
        swipe(e) {

            this.chooseName = e;
            this.getList()

        },
        //跳转 
        goTo(url, query) {
            this.$emit('jumpUrl', url, query);
        },
    }

}
</script>
<style>
.titleCon .slick-prev:before {

    background-image: url(../../assets/箭头-左-红.png);
    height: 38px;
    margin-top: -7px;
}

.titleCon .slick-next:before {

    background-image: url(../../assets/箭头-右-红.png);
    height: 38px;
    margin-top: -7px;
}
</style>
<style src="../../style/fonts/fontBody.css"></style>
<style scoped lang="scss">
.headerLeft {
    display: flex;
    align-items: center;
}

.arrowImg {
    width: 10px;
    height: auto;
}

.companyItem {
    width: 45%;
    padding: 20px;
    border-radius: 5px;
    background-color: #F0F4FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    letter-spacing: 1px;
}

.imgHeader {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 20px;
}

.title {
    margin-top: 20px;
    font-family: 'SourceHanSansSC-Regular';
    font-size: 26px;
    color: #000;
    margin-bottom: 20px;

    img {
        width: 20px;
        height: auto;
    }

    span {
        margin: 0px 10px;
    }
}

.titleRight {
    width: 300px;
}

.titleCon {
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleLeft {

    width: calc(100% - 360px);
}

.cloudBody {
    padding: 40px 280px;
}

.titleItem {
    width: 95% !important;
    padding: 10px 0px;
    border-radius: 10px;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: 'SourceHanSansSC-Regular';
    font-weight: 600;
    text-align: center;

}

.chooseYes {
    color: #fff;
    background-color: #EA0E1E;
}

.chooseNo {
    color: #333333;
    background-color: #FEECED;

}

.ExhibitionLeftHeight {
    height: 500px !important;
    position: relative;
    border-radius: 10px;

    .carousel {
        width: 100%;
        height: 500px;

    }

    .ExhibitionLeftHeightTitle {

        width: 100%;

        overflow: hidden;

        div {
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        position: absolute;
        color: #fff;
        left: 0px;
        bottom: 0px;
        padding: 20px;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.4);

    }
}

.exhibitionList {
    margin: 20px 0px;
}

.companyFlex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
</style>