<template>
    <div class="consumptionDetails-wrap">
        <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        </div>
        <div class="wrapMain">

            <div class="consumptionDetails-head">
                <div>
                    <img :src="objInfo.photo" alt="">
                </div>
                <div class="head-title">
                    <div class="title1">{{ objInfo.title }}</div>

                </div>

            </div>
            <div class="detailContent">
                <el-row class="title">
                    <img src="../../assets/标题装饰.png" />
                    <span>企业信息</span>
                </el-row>

            </div>
            <div class="info">
                {{ objInfo.content }}
            </div>

            <div class="detailContent">
                <el-row class="title">
                    <img src="../../assets/标题装饰.png" />
                    <span>经营范围</span>
                </el-row>

            </div>
            <div class="info">
                {{ objInfo.introduction }}
            </div>
            <div class="detailContent">
                <el-row class="title">
                    <img src="../../assets/标题装饰.png" />
                    <span>公司产品</span>
                </el-row>

            </div>
            <div class="info">
                <div style="display: flex;flex-wrap:wrap">
                    <div class="tupian" v-for="(item, index) in picList">

                        <el-image class="elImg" 
                            :src="item.photo"
                            :preview-src-list="picList.map(b => { return b.photo })">
                        </el-image>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    props: ["query"],

    data() {
        return {
            dialogVisible: false,
            objInfo: "",

            titleJumpList: [
                {
                    url: "CloudExhibitionHall",
                    name: "云上展览"

                }, {
                    url: "detailpageThree",
                    name: "企业详情"

                },
            ],
            picList: []

        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        getDetail() {
            this.$baseRequestGet('/not/online/exhibition/details?info=' + this.query, {}, 'get').then(res => {
                this.objInfo = res.data;
                this.$baseRequestGet('/not/onlineExhibitionPhoto/details?info=' + this.query, {}, 'get').then(res => {
                    this.picList = res.data;

                })
            })
        },
        //跳转 
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },


    }
}
</script>

<style src="@/style/element-ui-class.css"></style>

<style>
.consumptionDetails-wrap .el-input__inner {
    background-color: #f5f6f8 !important;
    height: 55px;
    line-height: 55px;
    border: none;
}

.consumptionDetails-wrap .el-textarea__inner {
    background-color: #f5f6f8 !important;
    border: none;
}
</style>

<style scoped lang="scss">
.wrapMain {
    background-color: #fff;
    padding: 50px 200px;
}

.consumptionDetails-wrap {
    padding: 40px 300px;
    // background-color: #fff;

    .consumptionDetails-head {
        border-bottom: 1px solid #e7e9eb;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-start;

        img {
            width: 150px;
            height: 150px;
            margin-right: 20px;
            border-radius: 10px;
        }

        .head-title {
            .title1 {
                font-size: 27px;
                font-weight: 400;
            }

            .title2 {
                margin-top: 10px;
                font-size: 18px;
                color: #666666;
            }
        }

        .head-btn {
            display: flex;
            align-items: center;
            justify-content: right;

            img {

                width: 107px;
                height: 35px;
                vertical-align: bottom;
                cursor: pointer;
                // object-fit: contain;
            }
        }
    }

    .rich-text {
        margin-top: 20px;
        font-size: 15px;

        .richPic {
            text-align: center;
        }

        .wall-detail {
            width: 50%;

            margin-bottom: 20px;
        }
    }

    .dialog {
        text-align: center;
        padding: 0 45px 30px 45px;

        .dialog-title {
            color: #000;
            font-size: 22px;
            font-weight: bold;
        }

        .dialog-input {
            margin-top: 40px;
        }

        .dialog-btn {
            margin-top: 30px;
        }

        .sumbitBox {
            width: 220px;
            height: 44px;
            // cursor: pointer;
        }
    }
}

.title {
    img {
        width: 25px;
        height: 25px;
    }

    span {
        line-height: 25px;
        margin-left: 9px;
        font-size: 29px;
        font-family: 'DingTalk_JinBuTi_Regular';
    }
}

.detailContent {
    margin-top: 40px;
}

.info {
    font-size: 17px;
    margin-top: 20px;
    letter-spacing: 1px;
    line-height: 140%;
    color: #121212;
    margin-bottom: 20px;
}

.tupian {
    .elImg {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        margin-right: 20px;
    }
}

.FixedContentStyle {
    margin-bottom: 20px;
}
</style>