<template>
    <div class="body">
        <el-row>
            <div class="meeting">
                <div v-for="(item, index) in meeting" :key="index" class="meeting-item" @click="meetingClick(index)"
                    :class="meetingIndex == index ? 'meeting-item-click' : ''">
                    {{ item.name }}
                </div>
            </div>
        </el-row>
        <div style="padding-top: 20px;">
            <el-row v-show="meetingIndex == 0">
                <el-image class="zhanwei" :src="srcDaohang" :preview-src-list="[srcDaohang]"></el-image>
            </el-row>
            <el-row v-show="meetingIndex == 1">
                <el-image class="zhanwei" :src="srcDaohang1" :preview-src-list="[srcDaohang1]"></el-image>
            </el-row>
            <el-row v-show="meetingIndex == 2">
                <el-image class="zhanwei" :src="srcDaohang2" :preview-src-list="[srcDaohang2]"></el-image>
            </el-row>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            srcDaohang: require('../../assets/辽洽导航/导航图-占位图.png'),
            srcDaohang1: require('../../assets/辽洽导航/平面图1.png'),
            srcDaohang2: require('../../assets/辽洽导航/交通图.png'),

            params: {
                cityName: "",
            },
            meeting: [{
                id: 1,
                name: "展会导览图"
            }, {
                id: 2,
                name: "展馆平面图"
            }, {
                id: 3,
                name: "展馆交通图"
            }], //辽恰会
            meetingIndex: 0, //辽恰会选择下标

        }
    },
    mounted() { },
    methods: {
        meetingClick(index) {
            this.meetingIndex = index;
          
        }
    }


}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    // background-color: #f6f7f9;

    .meeting {
        margin: 0px 47px;
        display: flex;
        justify-content: space-around;
        background-color: #ffdfe1;
        padding: 5px 0px;
        border-radius: 25px;

        &-item {
            cursor: pointer;
            padding: 7px 45px;
            font-family: "SourceHanSansSC-Regular";
            font-size: 19px;
            color: #ec2937;
        }

        &-item-click {
            border-radius: 20px;
            background-color: #ec2937;
            color: #fff;
        }
    }



    .title {
        height: 40px;

        img {
            width: 25px;
            height: 25px;
        }

        >span {
            line-height: 40px;
            margin-left: 9px;
            font-size: 32px;
            font-family: 'DingTalk_JinBuTi_Regular';
        }

        .input {
            float: right;
            width: 400px;

            >input {
                height: 40px !important;
            }

            .el-input__icon {
                line-height: 0px;
            }

            .el-input__icon::before {
                font-size: 20px;
            }
        }
    }

    .title.margin {
        margin: 58px 0px 20px 0px;
    }

    >.content {
        margin-top: 10px;

        img {
            width: 100%;
            height: 250px;
        }

        .text {
            background-color: #fff;
            padding: 25px 20px 50px 20px;
            border: 1px solid #ececec;
            margin-bottom: 30px;

            &-title {
                font-size: 21px;
                text-align: center;
                font-family: "思源黑体CN-Bold";
            }

            &-content {
                height: 40px;
                line-height: 20px;
                margin-top: 11px;
                font-size: 15px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.zhanwei {
    width: 100%;
    height: auto;
    // margin-top: 40px;
}

.pingmiantu {
    display: flex;
    justify-content: space-between;

    div {
        width: 49%;
    }

    img {
        width: 100%;
        height: auto;
    }
}
</style>