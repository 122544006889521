<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-12 17:11:48
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-21 16:29:20
 * @FilePath: \pc\src\view\ImportantActivity\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
         <div class="FixedContentStyle">
            <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl" ></FixedContent>
         </div>
   
         <div class="content">
			<div class="title">
				<img src="../../assets/标题装饰.png" alt="">
				<span>党建引领  乡村振兴</span>
				<img src="../../assets/标题装饰.png" alt="">
			</div>
            <div style="text-align: center;" class="titleLine">
                ——<span>小平房村与农场村联村共富新模式</span>——
            </div>
            <div class="contAll">
                <div class="conDetail">
                    在国家乡村振兴战略的宏伟蓝图下，建平县小平房村与朝阳县六家子镇农场村携手并进，共同书写着乡村振兴的新篇章。两村之间签订的乡村振兴共建协议，不仅是对党中央、国务院关于乡村振兴重大决策部署的积极响应，更是两地人民共同迈向富裕生活的坚实步伐。
                </div>
                <div>
                    <el-image  class="banner-img" :src="tupian1" :preview-src-list="[tupian1]"></el-image>
                    <!-- <img src="../../assets/乡村振兴/图片1.png" alt=""> -->
                </div>
                <div class="conDetail">
                    共建协议中，双方明确提出了“党建引领、联村共建、区域共富”的发展模式。这一模式，既强调了党的领导在乡村振兴中的核心作用，又突出了资源共享、优势互补的合作原则。通过加强交流、人才培养、基础设施建设、农产品销售和信息共享等多方面合作，两村将共同构建一个互利共赢的发展格局。
                </div>
                <div>
                    <el-image  class="banner-img" :src="tupian2" :preview-src-list="[tupian2]"></el-image>
                    <!-- <img src="../../assets/乡村振兴/图片2.png" alt=""> -->
                </div>
                <div class="conDetail">
                    在党建引领方面，选好书记，发挥头雁作用，是乡村振兴的关键。一个优秀的村党组织书记，能够带领村民团结一心，共同奋斗，将党的建设和乡村振兴紧密结合，实现强村富民的目标。农场村在借鉴小平房村经验的同时，也应注重结合自身实际，创新党建引领的方式方法，为乡村振兴注入新的活力。
                </div>
                <div>
                    <el-image  class="banner-img" :src="tupian3" :preview-src-list="[tupian3]"></el-image>
                    <!-- <img src="../../assets/乡村振兴/图片3.png" alt=""> -->
                </div>
                <div class="conDetail">
                    除了党建引领，人才培养也是乡村振兴的重要支撑。两村将共同开展人才培养活动，提升村民的综合素质和专业技能，为乡村振兴提供有力的人才保障。同时，加强基础设施建设，优化服务网络，提高普遍服务能力，也是两村合作的重要内容。这些举措，将为两村的经济社会发展奠定坚实的基础。
                </div>
                <div>
                    <!-- <img src="../../assets/乡村振兴/图片4.png" alt=""> -->
                    <el-image  class="banner-img" :src="tupian4" :preview-src-list="[tupian4]"></el-image>
                </div>
                <div class="conDetail">
                    在农产品销售方面，小平房村将利用其先进经验和渠道优势，帮助农场村打造供销平台，带动农场村经济发展。这一合作，不仅有助于农场村农产品的销售和推广，还将有力壮大村集体经济。
                </div>
                <div>
                    <!-- <img src="../../assets/乡村振兴/图片5.png" alt=""> -->
                    <el-image  class="banner-img" :src="tupian5" :preview-src-list="[tupian5]"></el-image>
                </div>
                <div class="conDetail">
                    建平县小平房村与朝阳县六家子镇农场村的紧密合作，是乡村振兴道路上的一次有益尝试和探索。展望未来，两村之间的合作将不断深化和拓展。在共建工作领导小组的组织协调下，双方将定期开展共建活动，相互学习，分享经验，取长补短，实现乡村振兴的宏伟目标，共同迈向更加美好的未来。
                </div>
                <div>
                    <!-- <img src="../../assets/乡村振兴/图片6.png" alt=""> -->
                    <el-image  class="banner-img" :src="tupian6" :preview-src-list="[tupian6]"></el-image>

                </div>
            </div>
			
		</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tupian1: require('../../assets/乡村振兴/图片1.png'),
            tupian2: require('../../assets/乡村振兴/图片2.png'),
            tupian3: require('../../assets/乡村振兴/图片3.png'),
            tupian4: require('../../assets/乡村振兴/图片4.png'),
            tupian5: require('../../assets/乡村振兴/图片5.png'),
            tupian6: require('../../assets/乡村振兴/图片6.png'),
            titleJumpList: [
                {
                    url: "mainIndex",
                    name: "乡村振兴详情"

                }
            ]

        }
    },
    mounted() { },
    methods: {
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;
    .contAll {
        .banner-img {
            width: 100%;
            height: auto;
            margin: 10px 0px;
        }
    }
    .titleLine {
        text-align: center;
        font-size: 17px;
        margin: 20px 0px;
        span {
            margin: 0px 15px;
        }
    }
    .conDetail {
        text-indent: 2em;
        font-size: 17px;
        letter-spacing: 1px;
        line-height: 140%;
    }
    .content {
		margin-top: 30px;
		padding: 50px 150px;
		background-color: #fff;
		border-radius: 5px;
	}

	.title {
		text-align: center;
		font-family: 'SourceHanSansSC-Regular';
		font-size: 26px;
		color: #000;
		margin-bottom: 30px;

		img {
			width: 20px;
			height: auto;
		}

		span {
			margin: 0px 10px;
		}
	}
}
.FixedContentStyle {
    margin-left: 20px;
    margin: 20px;
}
</style>
