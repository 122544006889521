<template>
    <div class="mainIndex">
        <div class="contentBanner">

            <div class="bannersCon">

                <div class="zhongyaohuodong">
                    <div class="title">
                        <img src="../assets/标题装饰.png" alt=""> <span>主题活动 </span> <img src="../assets/标题装饰.png" alt="">
                    </div>
                    <div class="content">
                        <div class="contentitem">
                            <el-image class="banner-img" :src="srcNew" :preview-src-list="[srcNew]"></el-image>
                            <!-- <img src="../assets/重要活动-占位图.png" alt=""> -->
                        </div>
                        <div class="lookMoreNoraml">
                            <span style="cursor: pointer;" @click="goTo('ImportantActivity')">查看更多</span>
                            <img style="cursor: pointer;" src="../assets/查看更多箭头.png" alt="">
                        </div>
                    </div>
                    <div class="tonggao">

                        <div class="tonggaoflex" v-if="nodifyList.length">
                            <div @click="goTo('newsIndex', nodifyList[0], '通知公告')" style="cursor: pointer;">
                                {{ nodifyList[0].journalismTitle }}
                            </div>
                            <div class="more" @click="goTo('newsIndex', nodifyList[0], '通知公告')">

                                <span style="cursor: pointer;"> 查看更多</span>
                                <img style="cursor: pointer;" src="../assets/查看更多箭头.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 新闻资讯 -->
        <div class="consumption">
            <div class="title">
                <img src="../assets/标题装饰.png" alt=""> <span>辽洽快讯 </span> <img src="../assets/标题装饰.png" alt="">
            </div>
            <div class="Navigation">

                <div class="Navigation-top">
                    <div class="Navigation-top-left">
                        <el-carousel indicator-position="outside" class="ExhibitionLeftHeight">
                            <el-carousel-item v-for="(item, index) in bannerListSmall" :key="index + 'c'">
                                <div @click="goTo('newsIndex', item, '热点新闻')" style="cursor: pointer;">
                                    <el-image class="carousel" :src="item.journalismPhoto"></el-image>
                                    <div class="ExhibitionLeftHeightTitle">
                                        <div>{{ item.journalismTitle }}</div>
                                    </div>
                                </div>

                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="Navigation-top-right">
                        <div class="ExhibitionRightMain">
                            <div class="ExhibitionRightItem" @click="goTo('newsIndex', item, '热点新闻')"
                                v-for="(item, index) in newsList" :key="index + 'e'">
                                <div>
                                    <img :src="item.journalismPhoto" alt="">
                                </div>
                                <div>
                                    <div class="info">{{ item.journalismTitle }}</div>
                                    <div class="time">{{ item.journalismDate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="lookMoreNoraml">
                    <span style="cursor: pointer;" @click="goTo('liaoQiaExpress')"> 查看更多</span>
                    <img @click="goTo('liaoQiaExpress')" style="cursor: pointer;" src="../assets/查看更多箭头.png" alt="">
                </div>
            </div>
        </div>
        <!-- 高光时刻 -->
        <div class="gaoguangshike" v-if="showPicType">
            <div class="gaoguangshikeItem">
                <div class="titless">
                    <div class="titlesCont">
                        高光时刻
                    </div>
                    <div class="yingwen">
                        HIGHLIGHT MOMENT
                    </div>
                    <div class="gaoguangLeft">
                        <div v-for="(item, index) in gaoguangList"
                            @click="gaoguangListName = item.dictionariesName, getPic()"
                            :class="gaoguangListName == item.dictionariesName ? 'gaoguangcheck' : ''">
                            {{ item.dictionariesName }} <img src="../assets/高光时刻-箭头-白.png" alt="">
                        </div>
                    </div>
                    <img class="seemore" @click="goTo('HighlightMoment')" src="../assets/查看更多.png" alt="">
                    <div>

                    </div>
                </div>
                <div class="picBian">
                    <VueSlickCarousel v-bind="settings" v-if="showPic && picLists.length">
                        <div v-for="(ite, index) in picLists" class="picBianItem">
                            <img :src="ite.picturePhoto" alt="">
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
        <!-- VR逛展 -->
        <div class="vrzhanlan">
            <img style="cursor: pointer;" src="../assets/vr逛展入口.png" @click="goTo('CloudExhibitionHall')" alt="">
        </div>
        <!-- 消费辽宁 -->
        <div class="xiaofeiliaoning">
            <div class="title">
                <img src="../assets/标题装饰.png" alt=""> <span>消费辽宁 </span> <img src="../assets/标题装饰.png" alt="">
            </div>
            <div class="storeItemList">
                <div class="storeItems">
                    <div @click="checkStoreIndex = item; getStore()"
                        :class="checkStoreIndex == item ? 'storeCheck' : 'storeCheckNo'"
                        v-for="(item, index) in storeTitle">
                        {{ item }}
                    </div>

                </div>
                <div class="lookMoreNoraml">
                    <span style="cursor: pointer;" @click="goTo('consumptionLiaoning')"> 查看更多</span>
                    <img @click="goTo('consumptionLiaoning')" style="cursor: pointer;" src="../assets/查看更多箭头.png"
                        alt="">
                </div>
            </div>
            <div>
                <div class="store">
                    <div class="storeItem" v-for="(item, index) in storeList" :key="index + 'n'"
                        @click="goTo('consumptionDetail', item, checkStoreIndex)">
                        <div class="stoPics">
                            <el-image lazy class="wall-detail" :src="item.photo">
                                <div slot="placeholder" class="image-slot">
                                    <img src="@/assets/首页切图/缺省.png" alt="">
                                </div>
                            </el-image>
                        </div>
                        <div class="content">
                            <div class="contentName">
                                {{ item.title }}
                            </div>
                            <div class="adress">
                                {{ item.company }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- 商品手册 -->
        <div class="shangpinshouce">
            <img style="cursor: pointer;" src="../assets/商品手册.png" @click="goTo('handbook')" alt="">
        </div>
        <!-- 展会服务 -->
        <div class="zhanhuifuwu">
            <div class="zhanhuifuwuItem">
                <img class="seemore" @click="goTo('ExhibitionService')" src="../assets/查看更多.png" alt="">
                <div class="serveListItem">
                    <div v-for="(item, index) in serveList" class="item" @click="goTo(item.url, item.name)">
                        <div>
                            <img class="zhanhuiitemimg" :src="require(`@/assets/展会服务-${item.name}.png`)" alt="">
                        </div>
                        <div class="serveListName">
                            {{ item.name }}
                        </div>
                        <div class="serveListContent">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 历届盛况 -->
        <div class="liaoqiadaohang">
            <div class="title">
                <img src="../assets/标题装饰.png" alt=""> <span>历届盛况 </span> <img src="../assets/标题装饰.png" alt="">
            </div>

            <div class="shengkuang">
                <div v-for="(item, index) in lijieList">
                    <img @click="goTo('previousAchievements', item)" :src="require(`@/assets/历届成就/${item}.png`)" alt="">

                </div>
            </div>

            <div class="lookMoreNoraml" style="margin-top: 10px;">
                <span style="cursor: pointer;" @click="goTo('previousAchievements')"> 查看更多</span>
                <img @click="goTo('previousAchievements')" style="cursor: pointer;" src="../assets/查看更多箭头.png" alt="">
            </div>
        </div>
        <!-- 辽洽导航 -->
        <div class="liaoqiadaohang">
            <div class="title">
                <img src="../assets/标题装饰.png" alt=""> <span>辽洽导航 </span> <img src="../assets/标题装饰.png" alt="">
            </div>
            <el-image class="banner-img" :src="srcDaohang" :preview-src-list="[srcDaohang]"></el-image>

            <div class="lookMoreNoraml" style="margin-top: 10px;">
                <span style="cursor: pointer;" @click="goTo('NavigationPage')"> 查看更多</span>
                <img @click="goTo('NavigationPage')" style="cursor: pointer;" src="../assets/查看更多箭头.png" alt="">
            </div>
        </div>

        <div class="xiangcunzhenx">
            <div class="title">
                <img src="../assets/标题装饰.png" alt=""> <span>乡村振兴 </span> <img src="../assets/标题装饰.png" alt="">
            </div>
            <img @click="goTo('countryDetail')" src="../assets/乡村振兴/乡村振兴2.png" alt="">
            <div class="xiangcunzhenxCon">
                <div class="biaoti">
                    党建引领 乡村振兴
                </div>
                <div class="neirong">
                    在国家乡村振兴战略的宏伟蓝图下，建平县小平房村与朝阳县六家子镇农场村携手并进，共同书写着乡村振兴的新篇章。两村之间签订的乡村振兴共建协议，不仅是对党中央、国务院关于乡村振兴重大决策部署的积极响应，更是两地人民共同迈向富裕生活的坚实步伐。
                </div>
            </div>

        </div>

        <el-dialog title="展商登记" :visible.sync="showSign" width="25%" xss>
            <div style="text-align: center;">
                <img class="erweima" src="../assets/展上登记用二维码.png" alt="">
            </div>
            <span slot="footer" class="dialog-footer">


            </span>
        </el-dialog>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { videoPlayer } from 'vue-video-player'

export default {
    name: 'mainIndex',
    components: {
        videoPlayer,
        VueSlickCarousel,

    },
    data() {
        return {
            lijieList: ['第一届辽洽会', '第二届辽洽会', '第三届辽洽会', '第四届辽洽会'],
            showSign: false,
            gaoguangListName: '',
            gaoguangList: [],
            settings: {
                "infinite": true,
                "slidesToShow": 3,
                "speed": 500,
                "rows": 2,
                "slidesPerRow": 1,
                "arrows": false,
                "dots": true,
                "autoplay": true,
                "speed": 4000,
                "autoplaySpeed": 4000,
                "cssEase": "linear"
            },
            storeTitle: ['老字号', '品牌商品'],
            checkStoreIndex: '老字号',
            serveList: [{
                name: '主题活动',
                content: '重要活动时间及地点安排',
                url: 'ImportantActivity',
            }, {
                name: '辽洽概况',
                content: '本届辽洽会整体情况',
                url: 'exhibitionInfo',
            }, {
                name: '展商登记',
                content: '展商登记参展证申请',
                url: 'ExhibitionService',
            }, {
                name: '证件查询',
                content: '参展证件申请情况查询',
                url: 'ExhibitionService',
            }, {
                name: '酒店推荐',
                content: '展馆附近优质酒店推荐',
                url: 'exhibitionGuide',
            }, {
                name: '布展撤展路线',
                content: '布展撤展路线图展示',
                url: 'ExhibitionService',
            }, {
                name: '租赁服务',
                content: '展具及绿植等物品租赁',
                url: 'ExhibitionService',
            }, {
                name: '特装申报',
                content: '特装申报材料及流程',
                url: 'ExhibitionService',
            }],
            bannerListSmall: [],
            newsList: [],
            storeList: [],
            picLists: [],
            nodifyList: [],
            showPic: false,
            srcDaohang: require('../assets/辽洽导航.png'),
            srcNew: require('../assets/重要活动-占位图.png'),
            showPicType: true,


        }
    },
    mounted() {

        this.getNews();
        this.getStore();
        this.getShowPic();

        this.getVisit()
    },

    methods: {
        getShowPic() {
            this.$baseRequestGet('/maintain/picture/doesItExist', {}, 'get').then(res => {

                this.showPicType = res.data;
                if (res.data) {
                    this.getType();
                }
            })
        },
        jumpTemPlate() {
            this.$message.warning('功能暂未开放')
        },
        getType() {
            this.$baseRequest("/sys/dictionaries/firstStage?info=图片", {}, "get").then(res => {
                this.gaoguangList = res.data.slice(0, 5);
                this.gaoguangListName = this.gaoguangList.length ? this.gaoguangList[0].dictionariesName : '';
                this.getPic();
            })
        },
        //跳转 
        goTo(url, query, type) {
            if (url == 'previousAchievements' && query) {
                this.$baseRequest("/not/previous/achievements/journalism/findList", {
                    journalismCategory: query
                }, "post").then(res => {
                    this.$emit('jumpUrl', 'previousAchievementsDET', res.data[0].id);
                });
                return;
            }
            if (!url) {
                this.$message.warning('功能暂未开放');
                return;
            }
            if (query == '证件查询') {
                this.$emit('showDialogNeed')
            } else if (query == '展商登记') {
                this.showSign = true;

            } else if (url == 'newsIndex') {
                this.$emit('jumpUrl', url, {
                    id: query.id,
                    journalismCategory: "新闻资讯",
                    type: type
                })
            } else if (url == 'consumptionDetail') {

                this.$emit('jumpUrl', url, {
                    id: query.id,
                    type: type == '辽宁农产品' ? '农产品' : type,
                })
            } else {
                this.$emit('jumpUrl', url, query);


            }

        },
        getVisit() {
            this.$baseRequestGet('/visit/homeNum/add?info=' + '1', {}, 'get').then(res => {

            })
        },

        // 照片直播
        getPic() {
            this.picLists = []
            this.$baseRequest('/maintain/picture/findList', { pictureCategory: this.gaoguangListName, beginNum: 1, endNum: 13 }, 'post').then(res => {
                this.picLists = res.data;

                this.showPic = true;


            })
        },

        // 消费平台
        getStore(index) {

            this.$baseRequest('/not/goods/boutique/findList', {
                province: '',
                category:  this.checkStoreIndex == '老字号'? '老字号商品':  this.checkStoreIndex,
                beginNum: 1, endNum: 5
            }, 'POST').then(res => {
                this.storeList = res.data;
            })
        },
        // 展会资讯
        getNews() {
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '新闻资讯', beginNum: 1, endNum: 9 }, 'post').then(res => {
                this.newsList = res.data.slice(4, 8);
                this.bannerListSmall = res.data.slice(0, 4);

            })
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '新闻资讯', beginNum: 1, endNum: 2, journalismSecondaryClassification: '通知公告' }, 'post').then(res => {
                this.nodifyList = res.data
            })
        },


    }
}
</script>
<style src="../style/elementSingle.css"></style>
<style src="../style/viedo.css"></style>

<style src="../style/fonts/fontBody.css"></style>
<style>
.hiddenPaddingDia .el-dialog--center .el-dialog__body {
    padding: 0px;

}

.hiddenPaddingDia .el-dialog__body {
    padding: 0px !important;

}

.hiddenPaddingDia .el-dialog__header {
    display: none;
}
</style>

<style scoped lang="scss">
.xiangcunzhenx {
    padding: 30px 280px;
    padding-bottom: 50px;
    background-color: #fff;
    position: relative;

    .xiangcunzhenxCon {

        width: 750px;
        position: absolute;
        right: 280px;
        top: 220px;

        .biaoti {
            font-size: 29px;
            margin-bottom: 20px;
            font-weight: 600;
            letter-spacing: 1px;

        }

        .neirong {
            font-size: 17px;
            letter-spacing: 1px;
            line-height: 160%;
        }
    }

    img {
        width: 100%;
        height: auto;
        cursor: pointer;
        margin-top: 30px;
    }
}

.erweima {
    width: 100%;
}

/* 下面我们会解释这些 class 是做什么的 */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.mainIndex {
    font-family: 'SourceHanSansSC-Regular';

    .title {
        text-align: center;
        font-family: 'SourceHanSansSC-Regular';
        font-size: 26px;
        color: #000;

        img {
            width: 20px;
            height: auto;
        }

        span {
            margin: 0px 10px;
        }
    }

    .lookMoreNoraml {
        font-size: 16px;
        text-align: center;
        // margin-top: 40px;
        color: #373737;


        img {
            width: 8px;
            vertical-align: inherit;
            margin-left: 5px;
        }

    }

    .contentBanner {

        .banners {

            width: 100vw;
            height: auto;
        }

        .bannersCon {
            width: calc(100vw - 560px);
            margin: 0 auto;
            position: relative;

            font-size: 28px;
            color: #000;
            overflow: hidden;
            /* 这句是最主要的 可以使img显示在div的下面 */

            .bannersConFlex {
                background-color: #FFFFFF;
                border-radius: 10px 10px 0px 0px;
                display: flex;
                justify-content: space-between;
                padding: 50px 100px;

            }

            .bannersConFlexitem {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 21px;
                font-family: 'SourceHanSansSC-Regular';
                cursor: pointer;
            }

            .bannerlistimg {

                img {
                    width: 60px;
                    height: auto;
                }
            }

            .zhongyaohuodong {
                background-color: #F7F7F7;
                padding: 30px 80px;
                border-radius: 0px 0px 10px 10px;
                padding-bottom: 20px;



                .content {
                    padding: 40px;
                    background-color: #fff;
                    border-radius: 15px;
                    margin-top: 15px;

                    .contentitem {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 10px;

                        // margin-bottom: 30px;
                        .banner-img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .lookMore {
                        font-size: 20px;
                        text-align: center;
                        margin-top: 30px;
                        color: #373737;

                        img {
                            width: 10px;
                            vertical-align: inherit;
                            margin-left: 5px;
                        }

                    }



                }

                .tonggao {
                    width: 100%;
                    margin: 40px 0px;
                    height: 80px;
                    background-image: url(../assets/通知公告.png);
                    background-repeat: no-repeat;
                    background-size: contain;

                    .tonggaoflex {
                        height: 65px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 40px;

                        font-size: 20px;
                        padding-left: 200px;

                        .more {
                            font-size: 16px;
                        }

                        img {
                            width: 8px;
                            margin-left: 5px;
                            vertical-align: inherit;

                        }
                    }
                }

            }


        }

    }
}

.gaoguangshike {

    background-image: url(../assets/辽洽导航背景图.png);
    background-repeat: no-repeat;
    background-size: cover;



    .gaoguangshikeItem {
        width: 100%;
        height: 850px;
        background-image: url(../assets/高光时刻bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .picBian {
            width: 50vw;
            position: absolute;
            left: 680px;
            top: 250px;

            .picBianItem {
                width: 100%;

            }

            img {

                width: calc(100% - 10px);

                height: calc(100% - 20px);
            }
        }

        .titless {
            position: absolute;
            top: 150px;
            left: 280px;

            .titlesCont {
                font-size: 28px;
                color: #fff;
                letter-spacing: 3px;
            }

            .yingwen {
                font-size: 22px;
                color: #F7B1A3;
                margin-top: 10px;
            }

            .gaoguangLeft {
                font-size: 18px;
                color: #fff;
                margin-top: 40px;

                div {
                    margin-bottom: 10px;
                    cursor: pointer;
                    padding: 15px 25px;
                    border-radius: 10px;

                }

                img {
                    width: 20px;
                    height: auto;
                    vertical-align: middle;
                    margin-left: 20px;
                }
            }

            .seemore {

                width: 110px;
                height: auto;
                margin-left: 20px;
                cursor: pointer;
                margin-top: 40px;
            }

            .gaoguangcheck {
                border: 1px solid #EE5D43;
                background-color: #EC472B;
            }

        }
    }
}

.xiaofeiliaoning {
    padding: 0px 280px;

    .storeItemList {
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;

    }

    .storeItems {
        display: flex;

        div {
            margin-right: 50px;
            cursor: pointer;
        }


    }

    .storeCheck {
        font-size: 19px;
        position: relative;


    }

    .storeCheck::before {
        content: '';
        position: absolute;
        bottom: -12px;
        width: 100%;
        height: 4px;
        background-color: #EA0E1E;
        border-radius: 3px;

    }

    .storeCheckNo {
        font-size: 19px;
        color: #8C8C8C;
    }

    .store {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;

        .storeItem {
            width: 24%;
            flex-shrink: 0;
            background-color: #fff;
            border-radius: 10px;
            margin-right: 20px;
            cursor: pointer;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            .stoPics {
                width: 100%;
                height: 300px;
                overflow: hidden;
                margin-bottom: 0px;
                border-radius: 10px 10px 0px 0px;

                .wall-detail {
                    width: 100%;
                    height: 300px;
                }

                .image-slot {
                    img {
                        width: 100%;
                        height: 300px;
                    }

                }
            }

            img {
                display: block;
                width: 100%;
            }

            .content {

                padding: 30px 20px;
                border-radius: 0px 0px 10px 10px;

            }

            .contentName {
                font-size: 18px;
                font-weight: 600;
                color: #000;
                margin-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .adress {
                color: #6C6C6C;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .store :last-child {
        margin-right: 0px;
    }
}

.liaoqiadaohang {
    padding: 10px 280px;
    padding-bottom: 50px;
    background-image: url(../assets/辽洽导航背景图.png);
    background-repeat: no-repeat;
    background-size: cover;

    .banner-img {
        width: 100%;
        height: auto;
        margin-top: 40px;
        cursor: pointer;
    }
}

.zhanhuifuwu {
    padding: 0px 280px;
    padding-bottom: 50px;

    .zhanhuifuwuItem {
        width: 100%;
        height: 420px;
        background-image: url(../assets/展会服务.png);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        .seemore {
            position: absolute;
            left: 5%;
            top: 70%;
            width: 110px;
            height: auto;
            cursor: pointer;
        }

        .serveListItem {
            width: calc(100% - 300px);
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            // background-color: pink;
            position: absolute;
            left: 21%;
            top: -3%;
            padding-top: 60px;

        }

        .item {
            width: 25%;
            height: 200px;
            text-align: center;

            .zhanhuiitemimg {
                width: 50px;
                margin-bottom: 10px;
            }
        }

        .serveListName {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 7px;
        }

        .serveListContent {
            font-size: 16px;
            color: #B2B2B6;
        }
    }
}

.vrzhanlan {
    width: 100%;
    margin-bottom: 50px;

    img {
        width: 100%;
        height: auto;
    }
}

.consumption {
    margin-top: 50px;

    .Navigation {
        padding: 20px 280px;

        .Navigation-top {
            height: 500px;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 30px;


            .Navigation-top-left {
                width: 950px;

                .ExhibitionLeftHeight {
                    height: 500px !important;
                    position: relative;

                    .carousel {
                        width: 100%;
                        height: 500px;
                    }

                    .ExhibitionLeftHeightTitle {

                        width: 100%;

                        overflow: hidden;

                        div {
                            width: 80%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                        }

                        position: absolute;
                        color: #fff;
                        left: 0px;
                        bottom: 0px;
                        padding: 20px;
                        font-size: 18px;
                        background-color: rgba(0, 0, 0, 0.4);

                    }
                }
            }

            .Navigation-top-right {
                width: calc(100% - 990px);
                margin-left: 20px;

                .ExhibitionRightMain {
                    height: 500px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;
                }

                .ExhibitionRightItem {
                    display: flex;
                    align-items: center;
                    width: calc(100% - 20px);
                    background-color: #fff;
                    cursor: pointer;
                    padding: 13px 10px;


                    img {
                        width: 110px;
                        height: 80px;
                        margin-right: 10px;
                        vertical-align: baseline;
                        border-radius: 5px;
                        border: 1px solid #D7D7D7;
                    }

                    .time {
                        width: 100%;
                        font-size: 14px;
                        color: #A6ABBC;
                        width: 300px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .info {

                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // 控制多行的行数
                        -webkit-box-orient: vertical;

                        width: calc(100% - 110px);
                        font-size: 17px;
                        margin-bottom: 5px;
                        color: #000;
                    }
                }
            }
        }

        .detailContent {
            display: flex;
            justify-content: space-between;

            .detailItem {
                width: 20%;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .detailItem :hover {
                cursor: pointer;
                transition: all 0.3s;
                transform: translate(0, -10px)
            }
        }
    }



    .store {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;

        .storeItem {
            width: 24%;
            background-color: #fff;
            border-radius: 10px;
            margin-right: 20px;
            cursor: pointer;

            .stoPics {
                width: 100%;
                height: 280px;
                overflow: hidden;
                margin-bottom: 0px;
                border-radius: 10px 10px 0px 0px;

                .wall-detail {
                    width: 100%;
                    height: 280px;
                }

                .image-slot {
                    img {
                        width: 100%;
                        height: 280px;
                    }

                }

                // text-align: center;
            }

            // border-radius:10px;
            img {
                display: block;
                width: 100%;

            }

            .content {
                background-color: #fff;
                padding: 30px 20px;
                border-radius: 0px 0px 10px 10px;
            }

            .contentName {
                font-size: 20px;
                font-weight: 600;
                color: #000;
                margin-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .adress {
                color: #6C6C6C;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .store :last-child {
        margin-right: 0px;
    }



}

.shengkuang {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    div {
        width: calc(25% - 20px);

        img {
            width: 100%;
            height: auto;
            cursor: pointer;
        }
    }
}

.shangpinshouce {
    margin: 20px 280px;

    img {
        width: 100%;
        height: auto;
        cursor: pointer;
    }
}
</style>