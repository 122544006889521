<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-14 13:03:46
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-23 16:43:21
 * @FilePath: \ddd\src\view\consumptionLiaoning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="body">
		<el-row class="title">
			<img src="../../assets/消费辽宁/探索辽宁商品-标题-PC.png" />
		</el-row>
		<div class="conent">
			<div v-for="(item, index) in commodity" :key="index" class="gagag">
				<img :src="item.img" @click="goTo(item.url, item.name)" class="commodity" />
			</div>
		</div>
		<el-row class="title margin">
		     <div style="display: flex;justify-content: space-between;align-items: center;">
				<div>
				<img src="../../assets/消费辽宁/辽宁饮食文化-标题-PC.png" />
			</div>
			<div>
				<img src="../../assets/消费辽宁/探索品牌商品手册-标题-PC.png" />
			</div>
			 </div>
		</el-row>
		<div class="conent">
			<div v-for="(item, index) in culture" :key="index" class="fff">
				<img :src="item.img" @click="goTo(item.url, item.name)" class="culture" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			commodity: [{
				img: require("@/assets/消费辽宁/PC-老字号商品.png"),
				url: "consumptionDetail",
				name: "老字号"
			},{
				img: require("@/assets/消费辽宁/PC-辽宁品牌商品.png"),
				url: "consumptionDetail",
				name: "品牌商品"
			}], //商品
			culture: [{
				img: require("@/assets/消费辽宁/品味辽菜-PC.png"),
				url: "AttractInvestment",
				name: "品味辽菜"
			}, {
				img: require("@/assets/消费辽宁/辽宁步行街-pc.png"),
				url: "AttractInvestment",
				name: "辽宁步行街"
			}, {
				img: require("@/assets/消费辽宁/品牌商品手册.png"),
				url: "handbook",
				name: "品牌商品手册"
			},], //文化
		}
	},
	mounted() { },
	methods: {
		goTo(url, name) {
			this.$emit('jumpUrl', url, name)
		}
	}

}
</script>

<style lang="scss" scoped>
.body {
	padding: 55px 280px;
	

	.title {
		img {
			width: 472px;
			height: auto;
		}
	}

	.conent {
		display: flex;
		justify-content: space-between;
		margin-top: 22px;
		.gagag {
			width:  calc(50% - 20px);
		}
		.fff {
			width:  calc(33% - 10px);
		}
		.commodity {
			cursor: pointer;
			width: 100%;
			height: 300px;
		}

		.culture {
			cursor: pointer;
			width: 100%;
			height: 230px;
		}
	}

	.title.margin {
		margin-top: 45px;

		img {
			width: 472px;
			height: auto;
		}
	}
}
</style>