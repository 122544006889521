<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 19:10:06
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-21 13:10:35
 * @FilePath: \department-of-commerce\PC\src\view\components\openDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="FixedContent">

        <div v-for="(item, index) in titleJumpList" :key="index" class="ggg" @click="clickBack(index)">
            <div v-if="index == 0">
                <img class="FixedContent-img1" src="@/assets/内容页切图/面包屑-首页.png" />
            </div>
            <div v-else>
                <img class="FixedContent-img2" src="@/assets/内容页切图/查看更多箭头.png" />

            </div>
            <div>
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        titleJumpList: {
            type: Array
        }
    },
    data() {
        return {
            FixedContentList: []

        }
    },

    mounted() {


    },
    methods: {
        clickBack(index) {
            if(this.titleJumpList[index].name == '乡村振兴详情') {
                this.$emit('jumpUrl', this.titleJumpList[index].url)
                return;
            }

            if (['重点项目','重要新闻'].includes(this.titleJumpList[index].name)) {
                this.$emit('back', this.titleJumpList[index].name);
                return;
            }
            if (index == this.titleJumpList.length - 1) {
                return;
            };
            if (!this.titleJumpList[index].url) {
                this.$emit('back', this.titleJumpList[index].name)
            } else {
                this.$emit('jumpUrl', this.titleJumpList[index].url)
            }


        }
    }
}
</script>

<style scoped lang="scss">
.FixedContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 19px;
    color: #7E84A3;

    div {
        margin-right: 5px;
        cursor: pointer;
    }

    .ggg {
        display: flex;
        align-items: center;
    }

    .FixedContent-img1 {
        width: 19px;
        height: auto;
        vertical-align: top;
        margin-right: 5px;
        margin-top: 2px;
    }

    .FixedContent-img2 {
        width: 14px;
        height: auto;
        vertical-align: middle;
        margin-right: 5px;
    }
}
</style>