<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-07 15:55:13
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-26 14:13:53
 * @FilePath: \department-of-commerce\PC\src\view\pilotSite.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="importantAcitve-main">
        <div class="FixedContentStyle">
                <FixedContent :titleJumpList="titleJumpList" @jumpUrl="goTo"></FixedContent>
            </div>
        <div style="background-color: #fff;" class="mainpic">
        
            <div >
                <div class="meeting">
                    <div v-for="(item, index) in list" :key="index" class="meeting-item"
                        @click="meetingClick(item.dictionariesName)"
                        :class="check == item.dictionariesName ? 'meeting-item-click' : ''">
                        {{ item.dictionariesName }}
                    </div>
                </div>
            </div>
            <div class="seePic" :infinite-scroll-immediate="false" v-infinite-scroll="loadMore"
                infinite-scroll-distance="50">
                <div class="pic-wall" v-for="(item, index) in picList">

                    <div class="time">
                        <div class="line"></div>
                        <div>
                            {{ item.pictureDate }}
                        </div>
                    </div>
                    <div>
                        <el-image lazy class="wall-detail" v-for="(i, ind) in item.list" :src="i.picturePhoto"
                            :preview-src-list="item.list.map(b => { return b.picturePhoto })">
                            <div slot="placeholder" class="image-slot">
                                <img src="@/assets/首页切图/缺省.png" alt="">
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

export default {
    props: ["query"],
    data() {
        return {
            loading: false,
            count: 20,
            picList: [],
            templateList: [],
            cutList: [],
            num: 0,
            canFresh: false,

            list: [],
            check: '',
            titleJumpList: [
                {
                    url: "HighlightMoment",
                    name: "高光时刻"

                }, {
                    url: "",
                    name: "精彩瞬间"

                }
            ],
        }
    },
    mounted() {

        this.getType()
    },
    methods: {
        goTo(url, query) {
            this.$emit('jumpUrl', url, query);
        },
        getType() {
            this.$baseRequest("/sys/dictionaries/firstStage?info=图片", {}, "get").then(res => {
                this.list = res.data;
                this.check = this.query;
                this.getPicList();
            })
        },
        meetingClick(index) {
            this.check = index;
            this.getPicList();
        },
        loadMore() {
            if (this.canFresh) {
                this.secondCut();
            }
        },
        secondCut() {

            for (let index = 0; index < this.templateList.length; index++) {
                for (let i = 0; i < this.templateList[index].templateList.length; i++) {
                    if (!this.templateList[index].templateList[i].isUse) {
                        this.templateList[index].templateList[i].isUse = true;

                        let findNum = this.picList.findIndex(item => item.pictureDate == this.templateList[index].pictureDate)
                        if (findNum != -1) { // 已经有该标记
                            this.picList[findNum].list = [...this.picList[findNum].list, ...this.templateList[index].templateList[i].list]
                        } else { // 没有该标记
                            let arr = {
                                pictureDate: this.templateList[index].pictureDate,
                                list: this.templateList[index].templateList[i].list.map(item => { return item })
                            }
                            this.picList.push(arr);
                        }
                        return;
                    }
                }


            }

        },
        getPicList() {
            this.$baseRequest('/maintain/picture/findMap', { pictureCategory: this.check }, 'post').then(res => {
                this.templateList = [];
                this.cutList = [];
                this.picList = [];
                this.templateList = res.data;
                // 切片处理图片
                if (this.templateList.length != 0) {
                    this.getCutPic();
                }
            })
        },
        getCutPic() {

            // 以20 为 一组 切片
            this.canFresh = false;
            this.picList = []
            for (let index = 0; index < this.templateList.length; index++) {
                this.$set(this.templateList[index], 'templateList', [])
                let b = this.lengthlsit(this.templateList[index].list, 10)
                b.forEach(i => {
                    this.num = this.num + 1;
                    this.templateList[index].templateList.push({
                        isUse: false,
                        list: i
                    })
                })
            };
            if (this.templateList[0].templateList.length == 1) {
                this.templateList[0].templateList[0].isUse = true;

                this.picList.push({
                    pictureDate: this.templateList[0].pictureDate,
                    list: this.templateList[0].templateList[0].list.map(item => { return item })
                })

                if (this.templateList[0].templateList[0].list.length < 10) { // 再往里PUSH 一组数据 不够20
                    if (this.templateList.length > 1) {
                        this.templateList[1].templateList[0].isUse = true;
                        let arr = {
                            pictureDate: this.templateList[1].pictureDate,
                            list: this.templateList[1].templateList[0].list.map(item => { return item })
                        }
                        this.picList.push(arr)
                    }
                }
            } else {

                this.templateList[0].templateList[0].isUse = true;

                this.picList.push({
                    pictureDate: this.templateList[0].pictureDate,
                    list: this.templateList[0].templateList[0].list.map(item => { return item })
                })

            }

            this.canFresh = true;
        },
        lengthlsit(str, length) { // str:需要切割的数组，length需要切割的长度
            //获取要切割的数组的长度
            var length = parseInt(length);
            //如果长度小于要划分的长度，返回原数组
            if (str < length) {
                return [str];
            }
            var groups = [];
            //拆分成的二维数组的个数
            var number = Math.ceil(str.length / length);
            // 用slice方法，每四个截取一次
            for (var i = 0; i < number; i++) {
                groups.push(str.slice(length * i, length * (i + 1)));
            }
            return groups;
        },

    }
}
</script>


<style scoped lang="scss">
.FixedContentStyle {
    margin-bottom: 20px;
}
.mainpic {
    padding: 40px;
    background-color: #fff;
 
}

.meeting {
    margin: 0px 47px;
    display: flex;
    justify-content: space-around;
    background-color: #ffdfe1;
    padding: 5px 0px;
    border-radius: 25px;

    &-item {
        cursor: pointer;
        padding: 7px 45px;
        font-family: "SourceHanSansSC-Regular";
        font-size: 19px;
        color: #ec2937;
    }

    &-item-click {
        border-radius: 20px;
        background-color: #ec2937;
        color: #fff;
    }
}

.importantAcitve-main {
    // padding: 20px 280px;
    padding: 20px 280px;
    

    .mainPageTime {


        z-index: 999;
        position: relative;

        .broadcast {
            // width: calc(100vw - 750px);
            background-color: #fff;
            border-radius: 3px;
            padding: 50px;

            .wrapper {
                --font-color-dark: #323232;
                --font-color-light: #FFF;
                --bg-color: #F3F3F3;
                --main-color: #066BE9;
                position: relative;
                width: 100%;
                height: 50px;
                background-color: var(--bg-color);
                border-radius: 50px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
            }

            .option {
                // width: 14%;
                flex: 1;
                height: 48px;
                position: relative;
                top: 2px;
                left: 2px;
            }

            .input {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                appearance: none;
                cursor: pointer;
            }

            .btn {
                width: 100%;
                height: 100%;
                background-color: var(--bg-color);
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .span {
                color: var(--font-color-dark);
            }

            .input:checked+.btn {
                background-color: var(--main-color);
            }

            .input:checked+.btn .span {
                color: var(--font-color-light);
            }
        }


    }


    .pic-wall {
        // margin-top: 40px;

        .time {
            font-size: 28px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding-left: 125px;
            
        }

        .line {
            width: 7px;
            height: 7px;
            margin-right: 10px;
            background-color: #F86C14;
            border-radius: 50%;

        }

        .wall-detail {
            width: 320px;
            height: 180px;
            margin-right: 20px;
            margin-bottom: 20px;
        }

        .image-slot {
            img {
                width: 320px;
                height: 180px;
            }
        }

    }

    .seePic {
        height: 550px;
        overflow: auto;
        margin-top: 30px;
        text-align: center;
    }
}
</style>