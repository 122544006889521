<template>
    <div class="body">
        <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        <div class="content">
            <div class="title">
                <img src="../../assets/标题装饰.png" alt="">
                <span>{{ details.journalismTitle }}</span>
                <img src="../../assets/标题装饰.png" alt="">
            </div>

            <div class="ql-container ql-snow" style="border: none;">
                <div class="ql-editor" @click="showImage($event)" v-html="details.journalismContent"></div>
            </div>
        </div>
        <elImageViewer v-if="dialogVisible" :on-close="closeImage" :url-list="[url]" />
    </div>
</template>

<script>
export default {
    props: ["query"],
    data() {
        return {
            url: '',
            dialogVisible: false,
            titleJumpList: [
                {
                    url: "consumptionLiaoning",
                    name: "消费辽宁"

                }, {
                    url: "",
                    name: "品牌商品手册"

                },
            ],
            details: {
                title: "",
                content: ""
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$baseRequest('/maintain/journalism/findList', { journalismCategory: '消费辽宁',journalismSecondaryClassification: '品牌商品手册'}, 'post').then(res => {
                  this.getDetails(res.data[0].id)
            })
        },
        showImage(e) {
            if (e.target.tagName == 'IMG') {

                this.url = e.target.src
                this.dialogVisible = true
            }
        },
        closeImage() {
            this.dialogVisible = false
            this.url = '';
        },
        jumpUrl(e) {
            this.$emit('jumpUrl', e)
        },
        getDetails(id) {
            this.$baseRequest("/maintain/journalism/details?info=" + id, {}, "get").then(res => {
                this.details = res.data;
                if (this.details.journalismContent) {
                    this.details.journalismContent = this.details.journalismContent.replace(/<iframe/g, "<video controls style='width:100%;height:auto;'")
                }
            })
        }
    },

}
</script>

<style lang="scss" scoped>
.body {
    padding: 20px 280px;


    .content {
        margin-top: 30px;
        padding: 50px 150px;
        background-color: #fff;
        border-radius: 5px;
    }

    .title {
        text-align: center;
        font-family: 'SourceHanSansSC-Regular';
        font-size: 26px;
        color: #000;
        margin-bottom: 30px;

        img {
            width: 20px;
            height: auto;
        }

        span {
            margin: 0px 10px;
        }
    }
}

.tupian {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.conts {
    margin-top: 30px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 140%;

}
</style>