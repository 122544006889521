<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2023-09-01 10:55:04
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-23 09:45:08
 * @FilePath: \sizedemo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div style="position: relative;" v-if="!['/loginPage', '/sign'].includes($route.path)">
      <!-- 固定部分 -->
      <div class="contentBanner" :style="{ backgroundImage: `url(${imgUrl})` }">
        <div class="bannersCon" id="scollDing">
          <div class="bannersConFlex">
            <div class="bannersConFlexitem" @click="clickJump(item.url)" v-for="(item, index) in bannerListName"
              :key="index">
              <div class="bannerlistimg">
                <img :src="require(`@/assets/导航-${item.name}.png`)" alt="">
              </div>
              <div>
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- 侧边导航跟随 -->

        <div class="folloeChange" v-show="isShow == true">
          <div v-for="(item, index) in bannerListName" :key="index + 'follows'">
            <div class="faq-button">
              <img @click="clickJump(item.url)" :src="require(`@/assets/导航-${item.name}.png`)" alt="">
              <span class="tooltip">{{ item.name }}</span>
            </div>


          </div>

        </div>
        <!-- 内容部分 -->
        <mainPage :componentsName="componentsName" id="chatRef" @runMore="runMore">
        </mainPage>

      </div>

      <!-- 尾部 -->
      <div class="footbox">

        <div class="detail">
          <div>
            <div class="title">
              主办单位
            </div>
            <div class="con">辽宁省人民政府</div>

          </div>
          <div>
            <div class="title">
              展会信息
            </div>
            <div class="con">时间：2024年9月26-29日</div>
            <div class="con">地址：沈阳新世界博览馆4层展厅</div>
          </div>

          <div>
            <div class="title">
              云上辽洽会
            </div>
            <div>
              <img src="@/assets/PC端修改后切图/banner/云上展厅.png" alt="">
              <img class="ipdoN" src="@/assets/PC端修改后切图/banner/IP.png" alt="">
            </div>
          </div>
          <div>

            <div>

            </div>
          </div>
        </div>
      </div>
      <div class="codeBox">
        <!-- <span>版权所有：沈阳华君科技资讯有限公司</span>

        <span>备案号：辽ICP备16014804号-4</span>

        <span>技术支持：沈阳华君科技资讯有限公司</span>

        <span>@20230925</span> -->
        <span>备案号：辽ICP备16014804号-4</span>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
    <el-dialog title="修改用户名" :visible.sync="showModify" width="500px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div style="text-align: center;">

        <el-input style="width: 80%;" v-model="name" placeholder="请输入用户名"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="clickSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mainPage from './view/propIndex.vue'
export default {
  components: { mainPage },
  name: 'App',
  data() {
    return {
      imgUrl: require('./assets/背景图汇总/主要背景.png'),
      fatherScrollHeigh: 0,
      isShow: false,
      componentsName: 'mainIndex',
      // bannerListName: [{
      //   name: '首页',
      //   url: "mainIndex",
      // }, {
      //   name: '辽宁省情',
      //   url: "liaoningSituation",
      // }, {
      //   name: '投资辽宁',
      //   url: "ProjectLibrary",
      // }, {
      //   name: '消费辽宁',
      //   url: "consumptionLiaoning",
      // }, {
      //   name: '开放平台',
      //   url: "openLiaoning",
      // }, {
      //   name: '供采对接',
      //   url: "supplyPurchase",
      // }, {
      //   name: '历届成就',
      //   url: "previousAchievements",
      // }],

      bannerListName: [{
        name: '首页',
        url: "mainIndex",
      }, {
        name: '辽洽概况',
        url: "exhibitionInfo",
      }, {
        name: '主题活动',
        url: "ImportantActivity",
      }, {
        name: '云上展览',
        url: "CloudExhibitionHall",
      },{
        name: '供采对接',
        url: "supplyPurchase",
      }, {
        name: '新质辽宁',
        url: "liaoningSituation",
      }, {
        name: '投资辽宁',
        url: "ProjectLibrary",
      }, {
        name: '消费辽宁',
        url: "consumptionLiaoning",
      }, {
        name: '开放平台',
        url: "openLiaoning",
      }],
      name: '',
      userInfo: '',

      showModify: false,

    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);

  },
  mounted() {
    this.$nextTick(res => {
      this.fatherScrollHeigh = document.getElementById('chatRef').offsetTop;
    })
    window.addEventListener('scroll', this.handleScroll);

  },

  methods: {
    runMore(e) {
      this.clickJump(e)
    },
    handleScroll() {

      let scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (scroll > this.fatherScrollHeigh + 50) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    clickLogOut() {
      localStorage.setItem('yuanzang', '');
      let params = localStorage.getItem('yuanzang');
      this.userInfo = params ? JSON.parse(params) : '';
    },
    clickSure() {
      if (!this.name) {
        this.$message.warning('请输入用户名')
      };
      this.$baseRequest('/not/onlineEnterprise/modify', { id: this.userInfo.id, enterpriseName: this.name }, 'post').then(res => {
        localStorage.setItem('yuanzang', JSON.stringify(res.data));
        this.showModify = false;
        let params = localStorage.getItem('yuanzang');
        this.userInfo = JSON.parse(params);

        if (this.userInfo) {
          if (!this.userInfo.enterpriseName) {
            this.showModify = true;
          }
        }
      });
    },

    checkClick(index, path) {

      this.checkIndex = path || index;
      this.$router.replace({ path: this.checkIndex })
    },
    clickJump(e) {

      this.componentsName = e;
     
      switch (e) {
        case "mainIndex":
          this.imgUrl = require('./assets/背景图汇总/主要背景.png')
          break;
        case "liaoningSituation":
          this.imgUrl = require('./assets/背景图汇总/辽宁省情bg.png')
          break;
        case "liaoningSituation":
          this.imgUrl = require('./assets/背景图汇总/辽宁省情bg.png')
          break;
        case "openLiaoning":
          this.imgUrl = require('./assets/背景图汇总/开放平台bg.png')
          break;
        case "ebay":
          this.imgUrl = require('./assets/背景图汇总/开放平台bg.png')
          break;
        case "pilotFreeTradeZone":
          this.imgUrl = require('./assets/背景图汇总/开放平台bg.png')
          break;
        case "previousAchievements":
          this.imgUrl = require('./assets/背景图汇总/开放平台bg.png')
          break;

        default:

          this.imgUrl = require('./assets/背景图汇总/灰背景.png')
          break;
      }

      this.$scrollTo('#scollDing', 500, { offset: 0 })
    }
  }

}
</script>
<style>
html:has(.el-image-viewer__wrapper) {
  overflow: hidden !important;
}
.el-input-group__append {
  color: #EA0E1E !important;
}
.el-button:focus, .el-button:hover {
  color: #fff !important;
}
.el-button--primary {
  background-color: #EA0E1E !important;
    border-color: #EA0E1E !important;
}
.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
.banner-unify {
  width: 100%;
  height: 238px;
}

.htmlContent img {
  width: 100%;
  height: 100%;
}
</style>
<style src="../src/style/fonts/fontBody.css"></style>
<style src="../src/style/elementMain.css"></style>

<style scoped lang="scss">

.dingbutu {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}



.footbox {
  background-color: #222222;
  // height: 350px;
  color: #fff;
  padding: 50px 320px;

  .logo {

    img {
      width: 469px;
      height: 63px;
    }
  }

  .detail {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    img {
      width: 118px;
      height: 118px;
    }



    .con {
      margin-bottom: 8px;
      color: #BCBCBC;
      font-size: 15px;

    }

    .title {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 600;
    }

    .titleFlex {
      display: flex;

      .titleItem {
        margin-right: 20px;
      }
    }
  }
}

.codeBox {
  padding: 0px 310px;
  height: 45px;
  background-color: #000;
  color: #fff;
  line-height: 45px;
  text-align: center;
  font-size: 14px;

  display: flex;
  justify-content: space-around;

  span {
    display: block;
    margin-right: 30px;
  }
}

.contentTitle {
  background-color: #EA0E1E;
  display: flex;
  justify-content: center;
  align-items: center;

  .titleItem {
    position: relative;
    width: 160px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 19px;
    cursor: pointer;

    img {
      width: 18px;
      vertical-align: middle;
      margin-right: 3px;
    }
  }

  .titleItemCheck {
    position: relative;
    width: 160px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 19px;
    background-color: #D20D1B;
    cursor: pointer;


  }

  .tan {
    // opacity: 0;
    display: none;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    height: 150px;
    background-color: #fff;
    font-size: 18px;
    color: #000;
    text-align: left;
    // padding-left: 20px;
    z-index: 999;
    text-align: center;
  }

  .titleItemCheck:hover .tan {
    // opacity: 1;
    display: block;

  }

  .titleItem:hover .tan {
    // opacity: 1;
    display: block;
  }

}

.contentBanner {
  width: 100vw;
  height: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  padding-top: 465px;
  background-color: #F7F7F7;



  .banners {

    width: 100vw;
    height: auto;
  }

  .folloeChange {
    position: fixed;
    right: 220px;
    top: 30%;
    font-size: 20px;
    z-index: 100;
    text-align: center;

    img {
      width: 45px;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }

  .bannersCon {
    width: calc(100vw - 560px);
    margin: 0 auto;


    font-size: 28px;
    color: #000;

    .bannersConFlex {
      background-color: #FFFFFF;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      padding: 40px 100px;

    }

    .bannersConFlexitem {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-family: 'SourceHanSansSC-Regular';
      cursor: pointer;
    }

    .bannerlistimg {

      img {
        width: 60px;
        height: auto;
      }
    }

    .zhongyaohuodong {

      padding: 30px 80px;
      border-radius: 0px 0px 10px 10px;
      padding-bottom: 20px;



      .content {
        padding: 40px;
        background-color: #fff;
        border-radius: 15px;
        margin-top: 15px;

        .contentitem {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 10px;
          margin-bottom: 30px;

          div {
            width: calc(50% - 20px);

            img {
              width: 100%;
            }
          }
        }

        .lookMore {
          font-size: 20px;
          text-align: center;
          margin-top: 30px;
          color: #373737;

          img {
            width: 10px;
            vertical-align: inherit;
            margin-left: 5px;
          }

        }



      }

      .tonggao {
        width: 100%;
        margin: 40px 0px;
        height: 80px;
        background-image: url(@/assets/通知公告.png);
        background-repeat: no-repeat;
        background-size: contain;

        .tonggaoflex {
          height: 65px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 40px;

          font-size: 20px;
          padding-left: 200px;

          .more {
            font-size: 16px;
          }

          img {
            width: 8px;
            margin-left: 5px;
            vertical-align: inherit;

          }
        }
      }

    }


  }

}

/* From Uiverse.io by vinodjangid07 */
.faq-button {
  position: relative;
}


@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(0.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, 0.75, 1);
  }

  50% {
    transform: scale3d(0.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, 0.95, 1);
  }

  75% {
    transform: scale3d(0.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tooltip {

  width: 90px;
  position: absolute;
  top: -30px;
  left: -30px;
  opacity: 0;
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ff2525;
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  bottom: -15%;
  transition-duration: 0.3s;
}

.faq-button:hover .tooltip {
  top: -40px;
  opacity: 1;
  transition-duration: 0.3s;
}

.ipdoN {
  width: 250px !important;
  height: auto !important;
  margin-left: 40px !important;
}
</style>
