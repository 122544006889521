<!--
 * @Author: jiangjinchi 1468922694@qq.com
 * @Date: 2024-09-13 09:05:28
 * @LastEditors: jiangjinchi 1468922694@qq.com
 * @LastEditTime: 2024-09-17 13:58:52
 * @FilePath: \pc\src\view\detailPage\detailpageOne.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="body">
        <FixedContent :titleJumpList="titleJumpList" @jumpUrl="jumpUrl"></FixedContent>
        <div class="groggery">
            <div class="groggery-item" v-for="(item, index) in list" :key="index"
                @click="goTo('detailpageThree', item)">
                <div>

                    <img :src="item.picture" alt="">
                </div>
                <div class="groggery-right">
                    <div>
                        <div class="groggery-right-name">{{ item.name }}</div>

                        <div class="groggery-right-info">地址：{{item.address}}</div>
                        <div class="groggery-right-distance">距展览馆{{ item.distance }}</div>
                    </div>
                    <div class="groggery-right-price"><span style="color: #EA0E1E;">￥</span><span
                            class="price">{{ item.price }}</span><span style="color: #7A7A7A;">起</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            titleJumpList: [
				{
					url: "ExhibitionService",
					name: "展会服务"

				}, {
					url: "exhibitionGuide",
					name: "酒店推荐"

				},
			]
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        cityClick(e) {
            this.cityFage = e;

        },
        jumpUrl(e) {
			this.$emit('jumpUrl', e)
		},
        getList() {
            this.$baseRequest('/not/hotel/findList', {}, 'get').then(res => {
                this.list = res.data

            })
        },
        //跳转 
        goTo(url,item) {
            this.$emit('jumpUrl', url,item)
        },
    }

}
</script>

<style lang="scss" scoped>
.body {
    padding: 60px 280px;
    
    padding-top: 30px;


}
.groggery-right-distance {
    background-color: #FEEEEF;
    color: #EB1F2D;
    font-size: 16px;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 15px;
    margin-top: 20px;
}
.bodyTitle {
    display: flex;
    justify-content: space-between;

    div {
        width: 23%;

        img {
            width: 100%;
            cursor: pointer;
        }
    }
}

.title {
    margin-top: 20px;
    font-family: 'SourceHanSansSC-Regular';
    font-size: 26px;
    color: #000;
    margin-bottom: 20px;

    img {
        width: 20px;
        height: auto;
    }

    span {
        margin: 0px 10px;
    }
}

.flexitem {
    display: flex;
}

.flexitemleft {
    width: 25vw;
    padding: 20px;
    background-color: #fff;
}

.flexitemright {
    padding: 20px;
    width: 75vw;

    margin-left: 40px;
    background-color: #fff;
}

.list {
    height: 60px;
    border-radius: 8px;
    padding: 0 25px;
    display: flex;
    cursor: pointer;

    .titlep {

        font-size: 19px;
        line-height: 60px;
        display: inline-block;
    }

    .img {
        float: right;
        margin: auto 0px auto auto;

        img {
            width: 10px;
        }
    }
}

.clickyes {
    background-color: #EA0E1E;
    color: #fff;
}

.noclick {
    color: #333333;
}

.groggery {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    .groggery-item {
        width: 46%;
        display: flex;
        padding: 20px;
        background-color: #fff;
    }

    img {
        width: 180px;
        height: 180px;
        border-radius: 5px;
    }

    .groggery-right {
        width: calc(100% - 200px);
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .groggery-right-name {
            font-size: 26px;
            color: #000;
        }

        .groggery-right-address {
            font-size: 16px;
            color: #6C6C6C;
            margin: 10px 0px;

            img {
                width: 10px;
                height: auto;
                vertical-align: middle
            }
        }

        .groggery-right-info {
            font-size: 17px;
            color: #686868;
            margin-top: 20px;
        }

        .groggery-right-price {
            font-size: 14px;
            text-align: right;

            .price {
                font-size: 30px;
                color: #EA0E1E;
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }
}
</style>