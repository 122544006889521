import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=38d8df94&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "@/style/element-ui-class.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./detail.vue?vue&type=style&index=1&id=38d8df94&prod&lang=css&"
import style2 from "./detail.vue?vue&type=style&index=2&id=38d8df94&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d8df94",
  null
  
)

export default component.exports